import {Link} from 'react-scroll';
import styled from 'styled-components';

export const Button = styled(Link)<{
  primarybtn?: string;
  big?: boolean;
  dark?: boolean;
  fontBig?: boolean;
}>`
  border-radius: 50px;
  background: ${({primarybtn}) => (primarybtn ? 'transparent' : '#010606')};
  white-space: nowrap;
  padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
  color: ${({dark}) => (dark ? '#010606' : '#fff')};
  font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  text-shadow: 2px 2px 4px black;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({primarybtn}) => (primarybtn ? '#fff' : 'transparent')};
    color: black;
    text-shadow: none;
  }
`;
export const ButtonStandart = styled.button<{
  primarybtn?: string;
  big?: boolean;
  dark?: boolean;
  fontBig?: boolean;
}>`
  border-radius: 50px;
  background: ${({primarybtn}) => (primarybtn ? 'transparent' : '#010606')};
  white-space: nowrap;
  padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
  color: ${({dark}) => (dark ? '#010606' : '#fff')};
  font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
  font-weight: 500;
  letter-spacing: 0.2px;
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  text-shadow: 2px 2px 4px black;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({primarybtn}) => (primarybtn ? '#fff' : 'transparent')};
    color: black;
    text-shadow: none;
  }

  @media screen and (max-width: 768px) {
    font-size: 12px;
    padding: 8px 15px;
    background: #fff;
    color: black;
    text-shadow: none;
    margin: 0px;
  }
`;
