import { MdArrowForward } from 'react-icons/md';
import styled, { keyframes } from 'styled-components';

import { colors } from '../../utils/colors';

const CoverBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const CoverContent = styled.div`
  z-index: 3;
  max-width: 1200px;
  position: absolute;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(100vh / 22);
`;

const CoverBtnWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
`;

const Images = styled.div<{ image: string }>`
  transition: background-image 1s ease-in-out;
  background-image: url(${(props) => props.image});
  background-color: white;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  background-attachment: fixed;
  align-items: center;
  justify-content: center;
  height: 100%;
  -ms-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  
  @media screen and (max-width: 480px) {
    background-attachment: scroll;
  }
`;

const textAnimation = keyframes`
  0% {margin-top: 0;}
  10% {margin-top: 0;}
  20% {margin-top: -5.62rem;}
  30% {margin-top: -5.62rem;}
  40% {margin-top: -11.24rem;}
  60% {margin-top: -11.24rem;}
  70% {margin-top: -5.62rem;}
  80% {margin-top: -5.62rem;}
  90% {margin-top: 0;}
  100% {margin-top: 0;}
`;

const TextMain = styled.main`
  color: ${colors.gray100};
  font-size: 3rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

const SectionAnimate = styled.section`
  height: 67px;
  overflow: hidden;
  text-align: center;
  div:first-child {
    animation: ${textAnimation} 12s infinite;
  }
  > div > span {
    padding: 0.25rem 0.75rem;
    height: 2.81rem;
    margin-bottom: 2.6rem;
    display: inline-block;
    text-shadow: 2px 2px 4px black;
  }
  @media screen and (max-width: 768px) {
    font-size: 1.8rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 1.5rem;
  }
`;

export default {
    CoverBg,
    CoverContent,
    CoverBtnWrapper,
    ArrowForward,
    Images,
    TextMain,
    SectionAnimate,
};

