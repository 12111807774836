import React, {memo, useEffect, useState} from 'react';
import NextHead from 'next/head';

import {Button} from '../../components/Button/ButtonElements';
import Container from '../../components/Container';
import {MainLabel} from '../../components/Svg';

import S from './index.style';

const Home: React.FC = () => {
    const [index, setIndex] = useState(0);

    const images = [
        {url: '/assets/main/1.webp', text: 'Креативные идеи'},
        {url: '/assets/main/2.webp', text: 'Стиль и функциональность'},
        {url: '/assets/main/3.webp', text: 'Качество и индивидуальность'}
    ];

    useEffect(() => {
        const change = setTimeout(() => {
            if (index === images.length - 1) {
                setIndex(0);
            } else {
                setIndex((prev) => prev + 1);
            }
        }, 3000);
        return () => clearTimeout(change);
    }, [index]);

    return (
        <>
            <NextHead>
                <link rel="preload" href="/assets/main/1.webp" as="image" />
                <link rel="preload" href="/assets/main/2.webp" as="image" />
                <link rel="preload" href="/assets/main/3.webp" as="image" />
            </NextHead>
            <Container id="home">
                <S.CoverBg>
                    <S.Images
                        image={images[index].url}
                        role="img"
                        aria-label="home_background"
                    />
                </S.CoverBg>
                <S.CoverContent>
                    <S.TextMain>
                        <S.SectionAnimate>
                            {images.map((item) => (
                                <div key={item.url}>
                                    <span>{item.text}</span>
                                </div>
                            ))}
                        </S.SectionAnimate>
                    </S.TextMain>
                    <MainLabel />
                    <S.CoverBtnWrapper>
                        <Button
                            href="О Нас"
                            to="about"
                            offset={-70}
                            spy={true}
                            smooth={true}
                            primarybtn="true"
                        >
            Далее {<S.ArrowForward />}
                        </Button>
                    </S.CoverBtnWrapper>
                </S.CoverContent>
            </Container>
        </>
    );
};

export default memo(Home);
